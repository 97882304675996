<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
          <el-form :model="form" ref="form" :inline="true" label-position="left" style="display: inline;" class="search-box">
            <el-form-item label="导出时间" class="form">
                <el-date-picker
                    v-model="form.time"
                    unlink-panels
                    type="daterange"
                    format="yyyy-MM-dd"
                    :default-time="['00:00:00', '23:59:59']"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <div slot="ctrl-button" class="mb24px mb24px" style="display: inline;vertical-align: middle;">
          <el-button type="primary" @click="exportExcel" icon="el-icon-download" v-if="excelBtn === false">下载数据</el-button>
          <el-button type="primary" :loading="true" v-else>数据准备中...</el-button>
          <el-button type="primary" @click="clickExport" icon="el-icon-download" v-if="picBtn === false">下载图片文件</el-button>
          <el-button type="primary" :loading="true" v-else>数据准备中...</el-button>
        </div>
        
        <div class="backup-table">
            <el-table
                :data="tableData"
                stripe
                style="width: 100%">
                <el-table-column
                prop="queryTime"
                :formatter="formatDate"
                label="查询时间">
                </el-table-column>
                <el-table-column
                label="下载内容">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if="scope.row.operateType === 1" style="color: #606266;font-size: 14px;font-weight: 400;">表格文件</el-button>
                    <el-button type="text" size="small" v-if="scope.row.operateType === 0" style="color: #606266; font-size: 14px;font-weight: 400">图片文件</el-button>
                </template>
                </el-table-column>
                <el-table-column
                label="查询进度"
                width="250px">
                <template slot-scope="scope">
                    <el-progress :percentage="percentageVal(scope.row.progress)" :text-inside="true" :stroke-width="18"></el-progress>
                </template>
                </el-table-column>
                <el-table-column
                label="查询状态">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if="scope.row.isFinish === true" style="color: #606266;font-size: 14px;font-weight: 400;">已完成</el-button>
                    <el-button type="text" size="small" v-if="scope.row.isFinish === false" style="color: #F18300; font-size: 14px;font-weight: 400">准备中</el-button>
                </template>
                </el-table-column>
                <el-table-column
                prop="downloadLink"
                label="操作">
                <template slot-scope="scope">
                    <el-button @click="add(scope.row.downloadLink)" type="text" size="small" v-if="scope.row.downloadLink !== ''" style="font-size: 14px;font-weight: 400;">下载</el-button>
                    <el-button type="text" size="small" v-if="scope.row.downloadLink === ''" style="color: rgba(102, 102, 102, 1); font-size: 14px;font-weight: 400">下载</el-button>
                </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="backup-main">
            <div class="backup-main-title">
                <i class="iconfont icontishi" style="color: #FAAD14"></i>
                备份内容
            </div>
            <div class="backup-main-text">
                <p>
                    <span>表格文件：</span>
                    1.订单及调度；2.日志log操作记录；3.对账单-客户；4.对账单-客户结算明细；5.对账单-外请车主；6.对账单-外请车结算明细；7.出纳收付报表；8.图片列表；9.驾驶员列表；10.车辆列表；11.往来单位列表；12.往来单位联系人。
                </p>
                <p>
                    <span>图片文件：</span>
                    平台各个流程所上传的图片
                </p>
            </div>
        </div>
      </div>
    </div>
    <!--导出-->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        nav: [
          {name: '数据备份'}
        ],
        form: {
            time:[]
        },
        excelBtn: false,
        picBtn: false,
        excelSetInterval: null,
        picSetInterval: null,
        tableData: [],
        excelArr: [],
        piclArr: [],
        excelData: null,   //点击下载表格时数据
        picData: null,    //点击下载图片时数据
      }
    },
    methods: {
        // 导出
      async exportExcel(){
        if(this.form.time === null || this.form.time.length === 0 ){
            this.$message.error('请选择导出时间');
            return
        }else{
            console.log(this.form.time)
            if(this.form.time[1] - this.form.time[0] > 2591999000+24*3600*1000){
                this.$message({
                    message: '时间跨度最大为31天',
                    type: 'warning'
                });
                return
            }else {
                await this.$api.excelDataBackup({
                    startTime: this.form.time[0],
                    endTime: this.form.time[1],
                })
                await this.$message({
                    message: '数据准备中，请等候...',
                    type: 'warning'
                })
                this.excelSetInterval = setInterval(() => {
                    this.excelDataBackupStatus()
                }, 1000);
            }
        }
      },

      // 导出
      async clickExport(){
          if(this.form.time === null || this.form.time.length === 0 ){
            this.$message.error('请选择导出时间');
            return
        }else{
            if(this.form.time[1] - this.form.time[0] > 2591999000){
                this.$message({
                    message: '时间跨度最大为30天',
                    type: 'warning'
                });
                return
            }else {
                await this.$api.picDataBackup({
                    startTime: this.form.time[0],
                    endTime: this.form.time[1],
                })
                await this.$message({
                    message: '数据准备中，请等候...',
                    type: 'warning'
                });
                this.picSetInterval = setInterval(() => {
                    this.picDataBackupStatus()
                }, 1000);
            }
        }
      },
      //excel准备数据状态查询
      async excelDataBackupStatus(){
        this.excelData = await this.$api.getExcelDataBackupStatus({})
        if(this.excelData.downloadLink === '' && this.excelData.isFinish === true){
            window.clearInterval(this.excelSetInterval)
            return
        }else if (this.excelData.downloadLink === '' && this.excelData.isFinish === false){
            this.excelBtn = true
            if(this.picData === null){
                this.tableData = []
                this.tableData = [this.excelData]
            }else{
                this.tableData = [null,this.picData]
                this.tableData = [this.excelData,this.picData]
            }
        }else if(this.excelData.downloadLink !== '' && this.excelData.isFinish === true){
            if(this.picData === null){
                this.tableData = []
                this.tableData = [this.excelData]
            }else{
                this.tableData = [null,this.picData]
                this.tableData = [this.excelData,this.picData]
            }
            window.clearInterval(this.excelSetInterval)
            this.excelBtn = false
            //window.open(excelData.downloadLink)
        }
      },

      //图片准备数据状态查询
      async picDataBackupStatus(){
        this.picData = await this.$api.getPicDataBackupStatus({})
        if(this.picData.downloadLink === '' && this.picData.isFinish === true){
            window.clearInterval(this.picSetInterval)
            return
        }else if (this.picData.downloadLink === '' && this.picData.isFinish === false){
            this.picBtn = true
            if(this.excelData === null){
                this.tableData = []
                this.tableData = [this.picData]
            }else{
                this.tableData = [this.excelData,null]
                this.tableData = [this.excelData,this.picData]
            }
        }else if(this.picData.downloadLink !== '' && this.picData.isFinish === true){
            if(this.excelData === null){
                this.tableData = []
                this.tableData = [this.picData]
            }else{
                this.tableData = [this.excelData,null]
                this.tableData = [this.excelData,this.picData]
            }
            window.clearInterval(this.picSetInterval)
            this.picBtn = false
            //window.open(picData.downloadLink)
        }
      },

      //时间戳转换
      formatDate(row, column) {
        let date = new Date(parseInt(row.queryTime));
        let Y = date.getFullYear() + '-';
        let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
        let m = date.getMinutes()  < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return Y + M + D + h + m + s;
      },

      //进度条
      percentageVal(e){
          let percentage = (e[0]/e[1])*100
          return percentage
      },

      //通过外链进行备份下载
      add(val){
          window.open(val)
      },

      //进入页面查询正在进行或已完成的备份
      async allValue(){
        this.excelArr = await this.$api.getExcelDataBackupStatus({})
        this.piclArr = await this.$api.getPicDataBackupStatus({})
        if(this.excelArr.downloadLink === '' && this.excelArr.isFinish === true){
            this.tableData = [this.piclArr]
            if(this.piclArr.downloadLink !== ''){
                window.clearInterval(this.allSetInterval)
            }
        }else if(this.piclArr.downloadLink === '' && this.piclArr.isFinish === true){
            this.tableData = [this.excelArr]
            if(this.excelArr.downloadLink !== ''){
                window.clearInterval(this.allSetInterval)
            }
        }else if(this.excelArr.downloadLink === '' && this.excelArr.isFinish === true && this.piclArr.downloadLink === '' && this.piclArr.isFinish === true){
            this.tableData = []
            window.clearInterval(this.allSetInterval)
        }else {
            this.tableData = [this.excelArr,this.piclArr]
            if(this.excelArr.downloadLink !== '' && this.piclArr.downloadLink !== ''){
                window.clearInterval(this.allSetInterval)
            }
        }
      }
    },
    beforeDestroy() {
        clearInterval(this.excelSetInterval)
        clearInterval(this.picSetInterval)
        clearInterval(this.allSetInterval)
    },
    async mounted(){
        this.allSetInterval = setInterval(() => {
            this.allValue()
        }, 1000);
        // this.picSetInterval = setInterval(() => {
        //     this.picDataBackupStatus()
        // }, 1000);
    }
  }
</script>

<style lang='sass' scoped>
    .content-box
        /deep/ .el-form-item 
            width: auto
        .content-main
            .backup-table
                margin-top: 50px
            .backup-main
                margin-top: 50px
                .backup-main-title
                    font-family: Microsoft YaHei
                    font-weight: 400
                    color: rgba(51,51,51,1)
                .backup-main-text
                    padding: 21px
                    background: rgba(5,117,230,0.05)
                    border: 1px solid rgba(94,172,251,1)
                    border-radius: 4px
                    margin-top: 20px
                    color: rgba(102,102,102,1)
                    line-height: 24px
                    span
                        color: rgba(51,51,51,1)
                        font-weight: 400

</style>
